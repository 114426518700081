import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87828050"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex",
  style: {"width":"100%","position":"relative","height":"100%","overflow-y":"scroll"}
}
const _hoisted_2 = {
  style: {"width":"100%"},
  class: "flex justify-content-center"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chart = _resolveComponent("v-chart")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        style: _normalizeStyle([{"width":"100%","position":"relative"}, {minHeight: _ctx.data[0].count?.length*48 + 'px'}])
      }, [
        _createVNode(_component_v_chart, {
          ref: "chart",
          autoresize: "",
          option: _ctx.option,
          class: "chart"
        }, null, 8, ["option"])
      ], 4)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isLoaded)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "flex justify-content-between flex-wrap legend-container",
            style: _normalizeStyle({maxWidth: _ctx.data?.length*_ctx.SERIES_LEGEND_WIDTH + 'px'})
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (part) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "legend-block flex cursor-pointer",
                key: part.title,
                onClick: ($event: any) => (_ctx.emitClickOnLegend(part))
              }, [
                _createElementVNode("div", {
                  class: "color-block",
                  style: _normalizeStyle({backgroundColor: part.color})
                }, null, 4),
                _createElementVNode("span", null, _toDisplayString(part.title), 1)
              ], 8, _hoisted_3))
            }), 128))
          ], 4))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "flex justify-content-between flex-wrap legend-container",
            style: _normalizeStyle({maxWidth: 3*_ctx.SERIES_LEGEND_WIDTH + 'px'})
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3], (part, index) => {
              return _createElementVNode("div", {
                class: "legend-block flex align-items-center",
                key: index*Math.random()
              }, [
                _createVNode(_component_a_skeleton, {
                  class: "color-block without-margin",
                  active: "",
                  paragraph: { rows: 0 }
                }),
                _createVNode(_component_a_skeleton, {
                  class: "without-margin",
                  style: {"width":"90px"},
                  active: "",
                  paragraph: { rows: 0 }
                })
              ])
            }), 64))
          ], 4))
    ])
  ], 64))
}