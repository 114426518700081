
import {defineComponent} from "vue";
import ContractsCharacteristics from "@/components/contracts/ContractsCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import {ContractsApi} from "@/api/contracts.api";
import TableSettings from "@/components/base/TableSettings.vue";
import CounterpartyList from "@/components/counterparty/CounterpartyList.vue";
import CounterpartyFilter from "@/components/counterparty/CounterpartyFilter.vue";
import DepartmentsList from "@/components/departments/DepartmentsList.vue";
import DepartmentsFilter from "@/components/departments/DepartmentsFilter.vue";
import {suffix} from "@/consts/nameSuff";
import MonitoringObjectsList from "@/components/monitoring-objects/MonitoringObjectsList.vue";
import MonitoringObjectsFilter from "@/components/monitoring-objects/MonitoringObjectsFilter.vue";
import {currentUser} from "@/consts/currentUser";

export default defineComponent ({
  name: "ContractsView",
  components: {
    ContractsCharacteristics,
    EditForm,
    DeleteDialog,
    TableSettings,
    CounterpartyList, CounterpartyFilter,
    DepartmentsList, DepartmentsFilter,
    MonitoringObjectsList, MonitoringObjectsFilter
  },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    list: [],
    currentUser,
    selectorData: [
      {value: 'Контрагенты', menuCode: 'dictionary', childMenuCode: 'contractors' },
      {value: 'Подразделения', menuCode: 'dictionary', childMenuCode: 'divisions' },
      {value: 'Объекты', menuCode: 'monitoring', childMenuCode: 'object' }
    ],
    currentSelect: 'Контрагенты',
    type: "contracts",
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
    const accessParams = { module: 'dictionary', object: 'contracts' };
    const tabMap = {
      'tab_common_information_contracts': '1',
      'tab_relation_contracts': '3',
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("договора", `договор ${this.body.name}`);
    },
    getById() {
      ContractsApi.getById(this.id).then((res) => {
        this.body = res;
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      ContractsApi.edit(
          characteristics
      ).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      ContractsApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    handleDeleteRelatedObject(id) {
      if(this.currentSelect ==='Контрагенты') {
        ContractsApi.deleteCounterpartyFromContracts(this.id,id)
            .then(res => {
          this.applyCurrentRelatedFilter();
        })
      }
      if(this.currentSelect ==='Подразделения') {
        ContractsApi.deleteDepartmentsFromContracts(this.id,id)
            .then(res => {
              this.applyCurrentRelatedFilter();
            })
      }
    },
    test() {
      console.log(this.relatedObjects)
    }
  }
})
