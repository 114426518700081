
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import {reactive} from "vue";
import {Field} from "@/types/fields";
import {ChangelogListData} from "@/types/changelog";
import {changelogFields, getChangelogData} from "@/consts/fields/changelog.fields";
import {ChangelogFilterImp} from "@/components/classes/ChangelogFilterImp";
import {ChangelogApi} from "@/api/changelog.api";
import ChangeLogList from "@/components/changelog/ChangeLogList.vue";
import ChangelogFilter from "@/components/changelog/ChangelogFilter.vue";
@Options({
  components: {ChangelogFilter, ChangeLogList},
  mixins: [NSIBase],
  data: () => {
    return {
      totalCount: 0,
      size: 20,
      page: 1,
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      fields: reactive(changelogFields.slice() as Field<ChangelogListData>[]),
      list: [] as ChangelogListData[],
      filter: new ChangelogFilterImp() ,
      prepareData: getChangelogData(),
      incidentKeys: "" as keyof ChangelogListData
    }
  },
  mounted() {
    this.applyFilter();
  },
  methods: {
    test() {
      // ChangelogApi.applyFilter1({}).subscribe((res) => {
      //   console.log(res, 'res1')
      // })
      //
      // ChangelogApi.applyFilter3({}).subscribe((res) => {
      //   console.log(res, 'res3')
      // })
    },
    changeStateVisibleSidebarFilter() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    resultMethod(res) {
      this.list = res.data;
      this.totalCount = res.totalCount;
      this.isLoad = true;
    },
    searchMethod(_) {
      const filter  = this.defineFilterData
      filter[this.exceptKey] = +this.$route.query.relatedId;
      return ChangelogApi
          .applyFilter(_ ? {containsValue: this.search, sortParams: this.filter.sortParams}
              : this.filter, this.size, this.page);
    },
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },

})
export default class  Changelog extends Vue{
  search: string;
  list!: ChangelogListData[];
  filter: ChangelogFilterImp;
}
