
import {defineComponent} from "vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";

import ViewBase from "@/classes/ViewBase.vue";
import {ModelsApi} from "@/api/models.api";
import ModelCharacteristics from "@/components/models/ModelCharacteristics.vue";
import {suffix} from "@/consts/nameSuff";
import {currentUser} from "@/consts/currentUser";
import EditForm from "@/components/base/EditForm.vue";

export default defineComponent({
  name: "ModelView",
  mixins: [ViewBase],
  components: {
    ModelCharacteristics,
    EditForm,
    DeleteDialog,
  },
  data: () => ({
    list: [],
    body: {},
    visibleSidebarFilter: false,
    visibleSidebarTableSettings: false,
    currentUser
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    onSelect(value) {
      const ind = this.selectorData.findIndex(sd => sd.value === value);
      this.$router.replace({ query: {...this.$route.query, sel: ind}});
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog("модели", `модель ${this.body.name}`);
    },
    getById() {
      if (this.$refs.characteristics)
        this.$refs.characteristics.clean();

      ModelsApi.getById(this.id)
          .then(res => {
            this.body = res;
            suffix.next(' ' + this.body.name);
          }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristic = this.$refs.characteristics.formatCharacteristicsCopy();
      ModelsApi.save(characteristic).then(() => {
        this.getById();
      })
    },
    deleteObject() {
      ModelsApi.delete(this.id).then(res => {
        this.$router.go(-1);
      })
    }
  }
})
