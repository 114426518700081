
import {defineComponent} from "vue";
import CustomAttributesCharacteristics from "@/components/custom-attributes/CustomAttributesCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import {suffix} from "@/consts/nameSuff";
import {CustomAttributesApi} from "@/api/custom-attributes.api";
import {currentUser} from "@/consts/currentUser";

export default defineComponent (
    {
      name: "CustomAttributesView",
      components: {
        CustomAttributesCharacteristics,
        EditForm,
        DeleteDialog,
      },
      mixins: [ViewBase],
      data: () => ({
        body: {},
        currentUser
      }),
      mounted() {
        this.id = this.$route.params.id;
        this.getById();
      },
      beforeRouteLeave(to, from, next) {
        suffix.next(null);
        if (!this.$refs.characteristics.isWarning) {
          window.onbeforeunload = undefined;
          next();
          return;
        }
        const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
        if (answer) {
          window.onbeforeunload = undefined;
          next();
        } else {
          next(false);
        }
      },
      methods: {
        openDeleteDialog() {
          this.$refs.delete.openDialog("служебного атрибута", `контрагента ${this.body.name}`);
        },
        getById() {
          CustomAttributesApi.getById(this.id).then((res) => {
            this.body = res;
            suffix.next(' ' + res.name);
          }).finally(() => {
            this.$refs.characteristics.cleanWarning();
          });;
        },
        save() {
          if(this.$refs.characteristics.validate()) {
            return;
          }
          const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
          CustomAttributesApi.save(
              characteristics
          ).then(() => {
            this.getById();
          });
        },
        deleteObject() {
          CustomAttributesApi.delete(this.id).then(() => {
            this.$router.go(-1);
          });
        },
        hasSupportEngineerRole() {
          return this.currentUser.value.userRoles.includes('support_engineer_internal')
        },
      }
    }
)
