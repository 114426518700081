import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f269556"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pl-4 pr-4 pb-5 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewSettingCharacteristics = _resolveComponent("ViewSettingCharacteristics")!
  const _component_EditForm = _resolveComponent("EditForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!!_ctx.body)
      ? (_openBlock(), _createBlock(_component_EditForm, {
          key: 0,
          onSave: _ctx.save,
          data: {id: 32,isHasSave: !_ctx.hasAccessToSave('settings', 'performances'), isDel: _ctx.hasAccessToDelete('settings','performances')},
          withoutId: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ViewSettingCharacteristics, {
              body: _ctx.body,
              "fields-settings": _ctx.fields,
              ref: "characteristics"
            }, null, 8, ["body", "fields-settings"])
          ]),
          _: 1
        }, 8, ["onSave", "data"]))
      : _createCommentVNode("", true)
  ]))
}