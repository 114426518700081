
  import { defineComponent } from "vue";
  import ListBase from "@/classes/ListBase.vue";
  import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
  import {GroupsApi} from "@/api/groups.api";

  export default defineComponent({
    components: {DeleteDialog},
    mixins: [ListBase],
    props: {
      userId: {default: null},
    },
    data: () => ({
      id: ''
    }),
    methods: {
      deleteRelatedGroup() {
        if(this.userId) {
          GroupsApi.deleteGroupUser(this.userId, this.id).then(() => {
            this.onChange(this.currentPage, this.currentSize);
          });
        }
      },
      openDeleteDialog(id, name) {
        this.id = id;
        this.$refs.delete.openDialog("связи", `связь с группой ${name}`);
      }
    }
  });
