
import { defineComponent } from 'vue';
import { SupersetSettingsApi } from "@/api/superset-settings.api";

export default defineComponent({
  data() {
    return {
      visible: false,
      title: 'Настройка',
      settings: {
        url: '',
        dashboardUuid: '',
        supersetUser: '',
        supersetPassword: '',
      },
    };
  },
  methods: {
    async openDialog(title) {
      this.visible = true;
      this.title = `Настройка ${title}`;
      await this.fetchSettings();
    },
    async fetchSettings() {
      try {
        const response = await SupersetSettingsApi.getSettings();
        if (response) {
          this.settings = response;
        }
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    },
    async saveSettings() {
      try {
        await SupersetSettingsApi.saveSettings(this.settings);
        this.handleOk();
      } catch (error) {
        console.error("Failed to save settings:", error);
      }
    },
    handleOk() {
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
  },
});
