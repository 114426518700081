
import {defineComponent} from "vue";
import { SettingsApi } from "@/api/settings.api";
import ViewBase from "@/classes/ViewBase.vue";
import ViewSettingCharacteristics from "@/components/view-settings/ViewSettingCharacteristics.vue";
import {incidentsFields} from "@/consts/fields/incidents.fields";
import {areasFields} from "@/consts/fields/areas.fields";
import {executorUsersFields} from "@/consts/fields/executor-users.fields";
import {serviceFields} from "@/consts/fields/services.fields";
import {operationQueueFields} from "@/consts/fields/operation-queues.fields";
import {brandsFields} from "@/consts/fields/brands.fields";
import {equipmentTypesFields} from "@/consts/fields/equipment-types.fields";
import {groupsFields} from "@/consts/fields/groups.fields";
import {metricIndicatorFields} from "@/consts/fields/metrics-indicators.fields";
import {processFields} from "@/consts/fields/processes.fields";
import {metricGroupsFields} from "@/consts/fields/metrics-groups.fields";
import {monitoringObjectsFields} from "@/consts/fields/monitoring-objects.fields";
import {reportsFields} from "@/consts/fields/reports.fields";
import {modelsFields} from "@/consts/fields/models.fields";
import {channelTypesFields} from "@/consts/fields/channel-types.fields";
import {areaTypesFields} from "@/consts/fields/area-types.fields";
import {metricFields} from "@/consts/fields/metrics.fields";
import {areasResponsibilityFields} from "@/consts/fields/areas-responsibility.fields";
import {supportTeamFields} from "@/consts/fields/support-team.fields";
import {systemFields} from "@/consts/fields/system.fields";
import { rolesFields } from "@/consts/fields/roles.fields";
import { defineDtoObject, defineDtoSubject } from "@/helpers/defineDtoSubject";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  components: {ViewSettingCharacteristics},
  mixins: [ViewBase],
  data: () => ({
    body: {},
    freeSelectorData: [],
    fields: []
  }),
  mounted() {
    this.getViewSetting()
  },
  beforeUnmount() {
    suffix.next(null);
  },
  methods: {
    getViewSetting() {
      this.fields = defineDtoObject(this.$route.params.id);
      SettingsApi.get(this.$route.params.id)
          .then(res => {
            if(this.body && res.length) {
              this.body = res[0];
              this.body.fields = this.body.fields.map(_ => ({
                ..._,
                value: this.findFieldByKey(_.dtoField)?.name
              }))
            }else {
              this.body = {dtoObjectClass: this.$route.params.id}
            };
            SettingsApi.getFields(this.$route.params.id).then(res => {
              res.forEach(val => this.freeSelectorData.push({ value: this.findFieldByKey(val)?.name || val, key: val,}));
              this.$refs.characteristics.setSelectData(this.freeSelectorData);
            });
          });
    },
    findFieldByKey(key) {
      return this.fields?.find(({field}) => field === key);
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.data;
      characteristics.fields.forEach((_, i) => {
        _.fieldOrder = i+1;
      })
      SettingsApi.save(characteristics).then(() => {
        localStorage.removeItem(this.$route.params.id);
        this.$router.go(-1);
      });
    },
  }
})
