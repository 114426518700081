
import {reactive} from 'vue'
import {Field} from "@/types/fields";
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import {ChannelTypeListData} from "@/types/channel-type";
import TableSettings from "@/components/base/TableSettings.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import ChannelTypesList from "@/components/channel-types/ChannelTypesList.vue";
import ChannelTypesFilter from "@/components/channel-types/ChannelTypesFilter.vue";
import {ChannelTypesApi} from "@/api/channel-types.api";
import {ChannelTypesFilterImp} from "@/components/classes/ChannelTypesFilterImp";
import {getChannelTypesData, channelTypesFields} from "@/consts/fields/channel-types.fields";
import NSIBase from "@/classes/NSIBase.vue";
import {takeWhile} from "rxjs/operators";
import {setVisibleToFieldsInTable, сhannelTypeDtoSettings$} from "@/consts/settings";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    TableSettings,
    Accordion,
    ChannelTypesList,
    ChannelTypesFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as ChannelTypeListData[],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof ChannelTypeListData,
      fields: null,
      filter: new ChannelTypesFilterImp(),
      prepareData: getChannelTypesData(),
      currentUser
    };
  },
  setup() {
    return {
      locale
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      ChannelTypesApi
              .downloadExcel(filter)
              .then((res) => {
                this.$notification.open({
                  message: new TextDecoder().decode(res.data),
                  style: {
                    width: '600px',
                    marginLeft: `${400 - 600}px`,
                    fontSize: "14px",
                  },
                  placement: 'bottomRight',
                  duration: 3,
                });
              })
              .finally(() => (this.isLoadingExcel = false));
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    toggleSuffix() {
      this.search = '';
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddChannelTypes() {
      this.$router.push("/directory/channel-types/add");
    },
    searchMethod(_) {
      return ChannelTypesApi
          .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    сhannelTypeDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields
              = setVisibleToFieldsInTable(channelTypesFields, settings.fields) as Field<ChannelTypeListData>[];
        });
  },
})
export default class ChannelTypes extends Vue {
  list!: ChannelTypeListData[];
  search: string;
  filter: ChannelTypesFilterImp;
}
