import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pl-4 pr-4 mb-4 dashboard-wrapper view-container" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardAnalytic = _resolveComponent("DashboardAnalytic")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_DashboardRegionList = _resolveComponent("DashboardRegionList")!
  const _component_DashboardCard = _resolveComponent("DashboardCard")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
      onChange: _ctx.change
    }, {
      default: _withCtx(() => [
        (_ctx.hasAccessToRead('analytic', 'tab_widgets_analytics', null))
          ? (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: "1",
              tab: "Виджеты"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_DashboardAnalytic)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.hasAccessToRead('analytic', 'tab_statistics_by_region_analytics', null))
          ? (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: "2",
              tab: "Cводная статистика по регионам"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_DashboardRegionList)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.hasAccessToRead('analytic', 'tab_dashboard_analytics', null))
          ? (_openBlock(), _createBlock(_component_a_tab_pane, {
              key: "3",
              tab: "Дашборд"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_DashboardCard)
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["activeKey", "onChange"])
  ]))
}