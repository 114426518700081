
import { defineComponent } from 'vue'
import ViewBase from '@/classes/ViewBase.vue'
import EditForm from '@/components/base/EditForm.vue'
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue'
import UrgencyCharacteristics from '@/components/urgency/UrgencyCharacteristics.vue'
import { UrgencyApi } from '@/api/urgency.api'
import { suffix } from '@/consts/nameSuff'
import {currentUser} from "@/consts/currentUser";

export default defineComponent({
  components: { UrgencyCharacteristics, EditForm, DeleteDialog },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    currentUser
  }),
  mounted() {
    this.id = this.$route.params.id
    this.getById()
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null)
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined
      next()
      return
    }
    const answer = window.confirm(
      'Закрыть сайт? Возможно внесенные изменения не сохранятся!'
    )
    if (answer) {
      window.onbeforeunload = undefined
      next()
    } else {
      next(false)
    }
  },
  methods: {
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    getById() {
      UrgencyApi.getById(this.id)
        .then((res) => {
          this.body = res
          suffix.next(' ' + res.name)
        })
        .finally(() => {
          this.$refs.characteristics.cleanWarning()
        })
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog(
        'Срочности',
        `Срочность ${this.body.name}`
      )
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return
      }
      const characteristics = Object.assign({}, this.$refs.characteristics.data)
      UrgencyApi.save(characteristics).then(() => {
        this.getById()
      })
    },
    deleteObject() {
        UrgencyApi.delete(this.id).then(() => {
        this.$router.go(-1)
      })
    },
  },
})
