
import {Options, Vue} from "vue-class-component";
import NSIBase from "@/classes/NSIBase.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import CustomAttributesList from "@/components/custom-attributes/CustomAttributesList.vue";
import CustomAttributesFilter from "@/components/custom-attributes/CustomAttributesFilter.vue";
import {CustomAttributesFilterImp} from "@/components/classes/CustomAttributesFilterImp";
import {CounterpartyListData} from "@/types/counterparty";
import {customAttributesFields, getCustomAttributesData} from "@/consts/fields/custom-attributes.fields";
import {CustomAttributesApi} from "@/api/custom-attributes.api";
import {
  customAttributesListDtoSettings$,
  setVisibleToFieldsInTable
} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";
import {Field} from "@/types/fields";
import {CustomAttributesListData} from "@/types/custom-attributes";
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    TableSettings,
    CustomAttributesList,
    CustomAttributesFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      filter: new CustomAttributesFilterImp(),
      totalCount: 0,
      size: 20,
      page: 1,
      selectedList: [],
      search: "",
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      fields: null,
      list: [] as CounterpartyListData[],
      prepareData: getCustomAttributesData(),
      currentUser
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch();
      },
      deep: true
    },
  },
  methods: {
    routeToAddCounterparty() {
      this.$router.push("/directory/custom-attributes/add");
    },
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    changeStateVisibleSidebarFilter() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    updateList(value) {
      let column = '';
      if(value.columnKey === 'incidentNumber') {
        column = 'id'
      } else {
        column = value.columnKey;
      }

      let order = '';
      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "id", direction: "desc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    searchMethod(_) {
      return CustomAttributesApi
          .applyFilter2(this.defineFilterData, this.size, this.page)
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    customAttributesListDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields = setVisibleToFieldsInTable(customAttributesFields, settings.fields) as Field<CustomAttributesListData>[];
        })
  }
})
export default class CustomAttributes extends Vue{}
