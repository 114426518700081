
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getOperationQueueData} from "@/consts/fields/operation-queues.fields";
import { operationQueueSettings$, getFieldSettings } from "@/consts/settings";

export default defineComponent({
  name: "OperationQueueCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getOperationQueueData(),
    validationFields:[
      {field: "id", validators: ["required"]},
      {field: "name", validators: ["required"]},
    ],
  }),
  methods: {
  },
  mounted() {
    this.getCRUDSettings(107)
  }
})
