
import {defineComponent} from "vue";
import ContractsCharacteristics from "@/components/contracts/ContractsCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import {ContractsApi} from "@/api/contracts.api";

export default defineComponent ({
  name: "AddContracts",
  components: {ContractsCharacteristics, EditForm},
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      ContractsApi.save(
          characteristics
      ).then((res) => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.route(res.id);
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      if (!id) {
        this.$router.replace("/directory/contracts/");
        return;
      }
      this.$router.replace("/directory/contracts/" + id);
    },
  }
})
