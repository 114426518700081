import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  class: "flex flex-wrap justify-content-between",
  title: ""
}
const _hoisted_3 = { class: "dash-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardSupersetCard = _resolveComponent("DashboardSupersetCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DashboardSupersetCard, { settings: _ctx.supersetSettings }, null, 8, ["settings"])
      ])
    ])
  ]))
}