
import {defineComponent} from "vue";
import CounterpartyCharacteristics from "@/components/counterparty/CounterpartyCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import {CounterpartyApi} from "@/api/counterparty.api";

export default defineComponent ({
  name: "AddCounterparty",
  components: {CounterpartyCharacteristics, EditForm},
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    add() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      characteristics.supportTeamDefaultId = characteristics.supportTeamDefault.id;
      if( characteristics.responsible) {
        characteristics.responsibleId = characteristics.responsible.id
      } else {
        characteristics.responsibleId = null
      }
      CounterpartyApi.save(
          characteristics
      ).then((res) => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.route(res);
      });
    },
    route(id?) {
      this.$refs.characteristics.cleanWarning();
      this.$router.replace("/directory/counterparty/" + id);
    },
  },
})
