
import { defineComponent } from 'vue'
import EditForm from '@/components/base/EditForm.vue'
import UrgencyCharacteristics from '@/components/urgency/UrgencyCharacteristics.vue'
import { UrgencyApi } from '@/api/urgency.api'

export default defineComponent({
  components: { UrgencyCharacteristics, EditForm },
  data: () => ({
    body: {},
  }),
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined
      next()
      return
    }
    const answer = window.confirm(
      'Закрыть сайт? Возможно внесенные изменения не сохранятся!'
    )
    if (answer) {
      window.onbeforeunload = undefined
      next()
    } else {
      next(false)
    }
  },
  methods: {
    add() {
      if (this.$refs.characteristics.validate()) {
        return
      }
      const characteristics = Object.assign({}, this.$refs.characteristics.data)
      UrgencyApi.save(characteristics).then((res) => {
        this.route(res.id)
      })
    },
    route(id) {
      this.$refs.characteristics.cleanWarning()
      if (id) {
        this.$router.replace('/directory/urgency/' + id)
        return
      }

      this.$router.replace('/directory/urgency/')
    },
  },
})
