
import {reactive} from 'vue'
import {Field} from "@/types/fields";
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import ModelsList from "@/components/models/ModelsList.vue";
import ModelsFilter from "@/components/models/ModelsFilter.vue";
import {ModelsFilterImp} from "@/components/classes/ModelsFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {ModelsListData} from "@/types/models";
import {getModelsData, modelsFields} from "@/consts/fields/models.fields";
import {ModelsApi} from "@/api/models.api";
import {modelListDtoSettings$, setVisibleToFieldsInTable} from "@/consts/settings";
import {takeWhile} from "rxjs/operators";

@Options({
  components: {
    TableSettings,
    Accordion,
    ModelsList,
    ModelsFilter
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as ModelsListData[],
      search: "",
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      userId: null,
      incidentKeys: "" as keyof ModelsListData,
      fields: null,
      filter: new ModelsFilterImp(),
      prepareData: getModelsData()
    };
  },
  methods: {
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      ModelsApi
          .downloadExcel(filter)
          .then((res) => {
            this.$notification.open({
              message: new TextDecoder().decode(res.data),
              style: {
                width: '600px',
                marginLeft: `${400 - 600}px`,
                fontSize: "14px",
              },
              placement: 'bottomRight',
              duration: 3,
            });
          })
          .finally(() => (this.isLoadingExcel = false));
    },
    getSelectedEquipment(e) {
      this.selectedList = e;
    },
    handleClickOnRelateButton() {
      // if(this.exceptKey === 'exceptUserId') {
      //   this.relateGroups();
      //   return;
      // }
    },
    // relateGroups() {
    //   let data: string[] = [];
    //   this.selectedList.forEach(role => data.push(role+''));
    //   return ModelsApi.addUserGroups(+this.$route.query.relatedId, data).then(() => {
    //     this.$router.go(-1);
    //   })
    // },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
          {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddModel() {
      this.$router.push("/directory/models/add");
    },
    searchMethod(_) {
      const filter  = this.defineFilterData;
      filter[this.exceptKey] = +this.$route.query.relatedId;
      return ModelsApi
          .applyFilter2(_ ? {containsValue: this.search, sortParams: this.filter.sortParams}
          : this.filter, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    modelListDtoSettings$
        .pipe(
            takeWhile(() => !this.fields),
        )
        .subscribe(settings => {
          if(!settings) {
            return;
          }
          if(!settings.fields) {
            this.fields = settings;
            return;
          }
          this.fields = setVisibleToFieldsInTable(modelsFields, settings.fields) as Field<ModelsListData>[];
        })
  },
})
export default class Models extends Vue {
  list!: ModelsListData[];
  search: string;
  filter: ModelsFilterImp;
}
