
import { defineComponent } from "vue";
import RangePeriod, { Period } from "@/classes/RangePeriod.vue";
import { DashboardsApi } from "@/api/dashboards.api";
import HybridGraphic from "@/views/dashboards/HybridGraphic.vue";
import moment from "moment";
export default defineComponent({
  mixins: [RangePeriod],
  components: {
    HybridGraphic
  },
  props: {
    filterVisibility: {
      default: undefined
    },
    hasPie: {
      default: true
    },
    isAnalyticsLoaded: {
      default: false
    },
    data: {
      default: []
    },
    title: {
      default: ''
    },
    summaryTitle: {
      default: ''
    },
    hasPeriod: {
      default: true
    }
  },
  emits: ["changePeriod"],
  data: () => ({
    selectedPeriod: "week" as Period,
    amount: 1,
    graphicType: 'pie',
  }),
  mounted() {
    this.graphicType = this.hasPie ? 'pie' : 'bar'
  },
  methods: {
    changeStateVisibleSidebarFilter() {
      this.$emit("update:filterVisibility", !this.filterVisibility)
    },
    handleChangePeriod(ev) {
      const gap = moment(ev[1]).diff(moment(ev[0]), 'days');
      if(gap === 1 && !this.isCurrentPeriod('day')) {
        this.setPeriod('day');
        return;
      }
      if(gap === 7 && !this.isCurrentPeriod('week')) {
        this.setPeriod('week');
        return;
      }
      if((gap === 31 || gap === 30 || gap === 28) && !this.isCurrentPeriod('month')) {
        this.setPeriod('month');
        return;
      }
      if((gap === 365 || gap === 366) && !this.isCurrentPeriod('year')) {
        this.setPeriod('year');
        return;
      }
      if((gap === 182.5 || gap === 183) && !this.isCurrentPeriod('quarter')) {
        this.setPeriod('quarter');
        return;
      }
      if(gap !== 1 && gap !== 7 && gap !== 365 && gap !== 366) {
        this.setPeriod(null)
      }
    },
    handleClickOnLegend(part) {
      this.$emit('clickOnLegend', part)
    },
    setGraphicType(graphicType: 'bar' | 'pie'){
      this.graphicType = graphicType;
    },
    getSumAnalytics(data) {
      return data.reduce((prev, {count}) =>prev + count, 0) || 0;
    }
  },
  watch: {
    period: {
      handler(val) {
        this.$emit('changePeriod', this.period)
      },
      deep: true
    },
  },
})
