
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getNoticeTemplateData} from "@/consts/fields/notice-template.fields";
import Editor from "@tinymce/tinymce-vue";

export default defineComponent({
  name: "NoticeTemplateCharacteristics",
  components: {Editor},
  mixins: [CharacteristicBase],
  data: () => ({
    data: getNoticeTemplateData()
  }),
  mounted() {
    this.getCRUDSettings(106)
  }
})
