
import {Util} from "@/components/utils/Util";
import moment from "moment";
import {mixins, Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import FilterBase from "@/classes/FilterBase.vue";
import locale from "ant-design-vue/es/time-picker/locale/ru_RU";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {MonitoringObjectsFilterImp} from "@/components/classes/MonitoringObjectsFilterImp";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {ClientsApi} from "@/api/clients.api";
import {IncidentsApi} from "@/api/incidents.api";
import {CustomAttributesApi} from "@/api/custom-attributes.api";

export default defineComponent({
  name: "MonitoringObjectsFilter",
  components: {
    Accordion,
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    customDataFilterMethod(event, attr) {
      if (event.length > 0) {
        console.log(event[0]._d)
        this.filter.customAttributes = this.filter.customAttributes.map(res => {
          if (res.name === attr.attributeName) {
            res.dateTo = moment(event[1]._d).format("DD.MM.YYYY HH:mm:ss");
            res.dateFrom = moment(event[0]._d).format("DD.MM.YYYY HH:mm:ss");
          }
          return res;
        })
      }
      else {
        this.filter.customAttributes = this.filter.customAttributes.map(res => {
          if (res.name === attr.attributeName) {
            res.dateTo = '';
            res.dateFrom = '';
          }
          return res;
        })
      }
      this.$emit("filter", this.filter);
    },
    customBooleanFilterMethod(event, attr){
      this.filter.customAttributes = this.filter.customAttributes.map(res => {
        if (res.name === attr.attributeName) {
          res.value = attr.checkboxValue[0];
        }
        return res;
      })
      this.$emit("filter", this.filter);
    },
    customFilterMethod(event, attr){
      this.$emit("filter", this.filter);
    },
    getCustomAttributeList() {
      CustomAttributesApi.getSearchableCustomAttributes('monitoring_object')
          .then((res) => {
            this.customAttributesList = res;
            this.formatCustomAttributesListToFilter(this.customAttributesList);
          })
    },
    formatCustomAttributesListToFilter(customAttributesList) {
      this.filter.customAttributes = customAttributesList.map(attribute => {
        if(attribute.type === 'text') {
          return {
            label: attribute.name,
            value: '',
            ...attribute,
            name: attribute.attributeName,
          };
        }
        if(attribute.type === 'date') {
          return {
            label: attribute.name,
            dateFrom: '',
            dateTo: '',
            ...attribute,
            gapDateValueForDatepicker: [],
            name: attribute.attributeName,
          }
        }
        if(attribute.type === 'boolean') {
          return  {
            label: attribute.name,
            value: '',
            ...attribute,
            checkboxValue: [""],
            name: attribute.attributeName,
          }
        }
      })
    },
    changeFilter() {
      this.setQueryParams();
    },
    handleVisibleChange(e1) {
      console.log(e1);
    },
    onCheckLastValue(array, name) { // only last value
      Util.onCheckLastValue(array, name);
    },
    onCheckManyValues(array, name) { // many values
      Util.onCheckManyValues(array, name);
    },
    changeBrand(array) {
      let brands = array[0] === '' ? null : array;
      this.valuesForCheckboxes.modelsArr = [''];
      this.getModel(brands);
    },
    getAreas(areaName?) {
      const arrChecked = [];
      if (this.valuesForCheckboxes.areasArr.length > 0) {
        this.valuesForCheckboxes.areasArr.forEach(id => {
          const checked = this.valuesForCheckboxes.areas.filter(mg => mg.id === id);
          if (checked.length > 0) {
            arrChecked.push(checked[0]);
          }
        });
      }
      const name = areaName || this.areaName;
      IncidentsApi.getAreas({containsValue: name})
          .then(res => {
            this.valuesForCheckboxes.areas = Util.transformToCheckboxes(res.data);
            if(arrChecked.length > 0) {
              arrChecked.forEach(c => {
                if (!this.valuesForCheckboxes.areas.some(mg => mg.id === c.id)) {
                  this.valuesForCheckboxes.areas.push(c);
                }
              });
            }
          });
    },
    getTypes() {
      MonitoringObjectsApi
          .getTypesList()
          .then((res) => {
            this.valuesForCheckboxes.types = Util.transformToCheckboxes(res.data);
          });
    },
    getStatuses() {
      MonitoringObjectsApi
          .getStatuses()
          .then((res) => {
            this.valuesForCheckboxes.statuses = Util.transformToCheckboxes(res);
          });
    },
    getProducer() {
      MonitoringObjectsApi
          .getProducerList(null, 100)
          .then((res) => {
            this.valuesForCheckboxes.brands = Util.transformToCheckboxes(res);
          });
    },
    getParents() {
      MonitoringObjectsApi
          .getParents()
          .then((res) => {
            this.valuesForCheckboxes.parents = Util.transformToCheckboxes(res);
          });
    },
    getClients() {
      ClientsApi.applyFilter({}, 100, 0)
          .then((res) => {
            this.valuesForCheckboxes.clients = Util.transformToCheckboxes(res.data);
          });
    },
    getModel(brandsId = []) {
      MonitoringObjectsApi
          .getModelListWithSomeBrands('', 100, brandsId)
          .then((res) => {
            this.valuesForCheckboxes.models = Util.transformToCheckboxes(res);
          });
    },
    applyFilter() {
      this.filter.areaIds = (this.valuesForCheckboxes.areasArr.length === 1
          && this.valuesForCheckboxes.areasArr[0] === '')
          ? [] : this.valuesForCheckboxes.areasArr;
      this.filter.statuses = (this.valuesForCheckboxes.statusesArr.length === 1
          && this.valuesForCheckboxes.statusesArr[0] === '')
          ? [] : this.valuesForCheckboxes.statusesArr;
      this.filter.types = (this.valuesForCheckboxes.typesArr.length === 1
          && this.valuesForCheckboxes.typesArr[0] === '')
          ? [] : this.valuesForCheckboxes.typesArr;
      this.filter.brands = (this.valuesForCheckboxes.brandsArr.length === 1
          && this.valuesForCheckboxes.brandsArr[0] === '')
          ? [] : this.valuesForCheckboxes.brandsArr;
      this.filter.models = (this.valuesForCheckboxes.modelsArr.length === 1
          && this.valuesForCheckboxes.modelsArr[0] === '')
          ? [] : this.valuesForCheckboxes.modelsArr;
      this.filter.isActive = this.valuesForCheckboxes.activityArr[0] === ''
          ? null : this.valuesForCheckboxes.activityArr[0] === 'true';
      this.filter.clientIds = (this.valuesForCheckboxes.clientsArr.length === 1
          && this.valuesForCheckboxes.clientsArr[0] === '')
          ? [] : this.valuesForCheckboxes.clientsArr;
      this.filter.parentId = (this.valuesForCheckboxes.parentsArr.length === 1
          && this.valuesForCheckboxes.parentsArr[0] === '')
          ? null : this.valuesForCheckboxes.parentsArr[0];
      this.filter.deleted = this.valuesForCheckboxes.deleted[0] === ''
          ? null : this.valuesForCheckboxes.deleted[0] === 'true';

      this.filterChange(new MonitoringObjectsFilterImp());
      this.$emit("filter", this.filter);
    },
    setQueryParams() {
      if (!this.$route.query.isRelated) {
        let queryParams: any = {};
        this.setQueryFromFilterParam(queryParams, ['name', 'id', 'systemNum']);
        this.setQueryParamsArrDefault(queryParams, ['activityArr', 'areasArr' , "parents", "types" , "statuses" , "brands" , "models"]);
        if(this.valuesForCheckboxes.activityArr[0] !== '') {
          queryParams.activity = this.valuesForCheckboxes.activityArr[0] === 'true';
        }
        this.$router.push({query: queryParams});
      }
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['name', 'id', 'systemNum']);
      this.setCheckboxesArrFromQuery(query, ['activityArr', 'areasArr' , "parents", "types" , "statuses" , "brands" , "models"]);
      if(query.activity) {
        this.valuesForCheckboxes.activityArr[0] = query.activity;
      }
    },
    cleanFilter() {
      this.filter = new MonitoringObjectsFilterImp();
      this.defaultValueFilter();
      this.defaultValueCheckboxes(['clientsArr', 'areasArr']);
      this.setFilterFields();
      this.formatCustomAttributesListToFilter(this.customAttributesList);
      this.filterChange(new MonitoringObjectsFilterImp());
      this.$emit("filter", this.filter);
    },
    defaultValueFilter() {
      this.defaultValueCheckboxes([]);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'name') {
              this.filter.name = '';
            }
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'area') {
              this.filter.areaName = '';
            }
            if(f.field === 'ipaddress') {
              this.filter.ipaddress = '';
            }
            if(f.fields === "type"){
              this.valuesForCheckboxes.typesArr.length === 0 ?
              this.valuesForCheckboxes.typesArr = ['']
              :
              this.valuesForCheckboxes.typesArr
            }
            if(f.field === 'brand') {
              this.valuesForCheckboxes.brandsArr = [''];
            }
            if(f.field === 'model') {
              this.valuesForCheckboxes.modelsArr = [''];
            }
            if(f.field === 'status') {
              this.valuesForCheckboxes.statusesArr = [''];
            }
            if (f.field === 'isActive') {
              this.valuesForCheckboxes.activityArr = [''];
            }
            if (f.field === 'systemNum') {
              this.filter.systemNum = '';
            }
            if(f.field === 'area') {
              this.valuesForCheckboxes.areasArr = [''];
            }
          });
    },
  },
  data: () => ({
    filter: new MonitoringObjectsFilterImp(),
    areas: [],
    areaName: '',
    valuesForCheckboxes: {
      area: [],
      areasArr: [],
      types: [],
      typesArr: [],
      statuses: [],
      statusesArr: [],
      brands: [],
      brandsArr: [],
      models: [],
      modelsArr: [],
      parents: [],
      parentsArr: [],
      activity: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'},
      ],
      activityArr: [''],
      clients: [],
      clientsArr: [],
      archive: [
        {value: '', label: 'Неважно'},
        {value: 'true', label: 'Да'},
        {value: 'false', label: 'Нет'},
      ],
      deleted: ['false'],
    },
    filterData: {},
    customAttributesList: []
  }),
  mounted() {
    Promise.all([this.getTypes(), this.getStatuses(), this.getProducer(),
      this.getModel(), this.getParents(), this.getClients(), this.getAreas()])
        .then(() => {
          this.defaultValueFilter();
          this.getQueryParams();
          this.setFilterFields();
          this.applyFilter();
          this.getCustomAttributeList();
        });
  },
  setup() {
    return {
      locale
    }
  },
})
