import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03495214"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pl-4 pr-4 view-container" }
const _hoisted_2 = { class: "view-container-related-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionAgentCharacteristics = _resolveComponent("CollectionAgentCharacteristics")!
  const _component_EditForm = _resolveComponent("EditForm")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_SchedulerRunEventList = _resolveComponent("SchedulerRunEventList")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_delete_dialog = _resolveComponent("delete-dialog")!
  const _component_archive_dialog = _resolveComponent("archive-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change(_ctx.activeKey), _ctx.handleTabChange, _ctx.changeTab(_ctx.activeKey)))
      }, {
        default: _withCtx(() => [
          (_ctx.hasAccessToRead('settings','data_collection_agents', 'tab_common_information_data_collection_agents'))
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: "1",
                tab: "Общая информация"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_EditForm, {
                    onSave: _ctx.save,
                    onReject: _ctx.cancel,
                    onDelete: _ctx.openDeleteDialog,
                    onArchive: _ctx.openArchiveDialog,
                    data: {id: _ctx.body.id, isArchive: !_ctx.body.deleted, unArchive: _ctx.body.deleted,
                  isHasSave: _ctx.hasAccessToSave('settings','data_collection_agents', 'tab_common_information_data_collection_agents'),
         isDel: _ctx.hasAccessToDelete('settings','data_collection_agents', 'tab_common_information_data_collection_agents')}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CollectionAgentCharacteristics, {
                        ref: "characteristics",
                        body: _ctx.body
                      }, null, 8, ["body"])
                    ]),
                    _: 1
                  }, 8, ["onSave", "onReject", "onDelete", "onArchive", "data"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.hasAccessToRead('settings','data_collection_agents', 'tab_journal_data_collection_agents'))
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: "2",
                tab: "Журнал обработок"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_SchedulerRunEventList, {
                      list: _ctx.schedulerRunEvent.list,
                      totalCount: _ctx.schedulerRunEvent.totalCount,
                      fields: _ctx.schedulerRunEvent.fields,
                      isLoad: _ctx.isLoad,
                      onChangePage: _ctx.updatePage,
                      onChangeSorter: _ctx.updateList,
                      height: _ctx.heightForRelatedObjectList
                    }, null, 8, ["list", "totalCount", "fields", "isLoad", "onChangePage", "onChangeSorter", "height"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["activeKey"])
    ]),
    _createVNode(_component_delete_dialog, {
      ref: "delete",
      onUpdateDialog: _ctx.deleteObject
    }, null, 8, ["onUpdateDialog"]),
    _createVNode(_component_archive_dialog, {
      ref: "archive",
      onUpdateDialog: _ctx.archiveObject
    }, null, 8, ["onUpdateDialog"])
  ], 64))
}