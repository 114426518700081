
import {defineComponent} from "vue";
import CharacteristicBase from "@/classes/CharacteristicBase.vue";
import {getMonitoringObjectsData} from "@/consts/fields/monitoring-objects.fields";

export default defineComponent ({
  name: "MonitoringObjectServiceInformation",
  mixins: [CharacteristicBase],
  data: () => ({
    data:getMonitoringObjectsData()
  })
})
