
import {defineComponent} from "vue";
import DashboardAnalytic from "@/components/dashboards/DashboardAnalytic.vue";
import DashboardRegionList from "@/components/dashboards/DashboardRegionList.vue";
import DashboardCard from "@/views/dashboards/DashboardCard.vue";
import ViewBase from "@/classes/ViewBase.vue";

export default defineComponent({
  components: {
    DashboardAnalytic,
    DashboardRegionList,
    DashboardCard
  },
  mixins: [ViewBase],
  data: () => ({
    activeKey: '1',
  }),
  mounted() {
    this.getQueryParams();
  },
  methods: {
    change(activeKey) {
      this.$router.replace({ query: {...this.$route.query, tab: activeKey}});
    },
    getQueryParams() {
      const query = this.$route.query;
      if (query.tab) {
        this.activeKey = query.tab;
      }
    },
  }
});
