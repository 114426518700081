
import { defineComponent } from "vue";
import {UnitsApi} from "../../api/units.api";
import UnitCharacteristics from "@/components/units/UnitCharacteristics.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";

export default defineComponent({
  name: "UnitView",
  components: { UnitCharacteristics, DeleteDialog },
  mixins: [ViewBase],
  data: () => ({
    body: {},
  }),
  mounted() {
    this.id = this.$route.params.id
    this.getByName();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("единицу измерения", `единицу измерения ${this.body.name}`);
    },
    getByName() {
      this.body = {name: this.id};
      setTimeout(() => {
        this.$refs.characteristics.cleanWarning();
      }, 1000);
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristics();
      characteristics.oldName = this.id;
      characteristics.newName = characteristics.name;
      this.id = characteristics.newName +'';
      delete characteristics.name;
      UnitsApi.edit(
          characteristics
      ).then(() => {
        this.$router.push("/directory/units/" + characteristics.newName);
        this.getByName();
      });
    },
    deleteObject() {
      UnitsApi.delete(this.id).then((res) => {
        this.$router.go(-1);
      });
    },
  },
});
