
import {reactive} from 'vue'
import {Field} from "@/types/fields";
import {Options, Vue} from "vue-class-component";
import Accordion from "@/components/ui/Accordion.vue";
import TableSettings from "@/components/base/TableSettings.vue";
import BrandsList from "@/components/brands/BrandsList.vue";
import BrandsFilter from "@/components/brands/BrandsFilter.vue";
import {BrandsFilterImp} from "@/components/classes/BrandsFilterImp";
import NSIBase from "@/classes/NSIBase.vue";
import {BrandsListData} from "@/types/brands";
import {BrandsApi} from "@/api/brands.api";
import {brandsFields, getBrandsData} from "@/consts/fields/brands.fields";
import CollectionAgentList from "@/components/collection-agents/CollectionAgentList.vue";
import CollectionAgentsFilter from "@/components/collection-agents/CollectionAgentsFilter.vue";
import { CollectionAgentListData } from "@/types/collection-agents";
import { collectionAgentFields, getCollectionAgentData } from "@/consts/fields/collection-agents.fields";
import { CollectionAgentsImp } from "@/components/classes/CollectionAgentsImp";
import { CollectionAgentsApi } from "@/api/collection-agents.api";

@Options({
  components: {
    CollectionAgentsFilter,
    CollectionAgentList,
    TableSettings,
    Accordion,
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as CollectionAgentListData[],
      search: "",
      selectedList: [],
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      incidentKeys: "" as keyof CollectionAgentListData,
      fields: reactive(collectionAgentFields.slice() as Field<CollectionAgentListData>[]),
      filter: new CollectionAgentsImp(),
      prepareData: getCollectionAgentData()
    };
  },
  methods: {
    downloadExcel() {
      this.isLoadingExcel = true;
      const filter = Object.assign({}, this.defineFilterData);
      const fields = [];
      this.fields.forEach(field => {
        if(!field.value) {
          return;
        }
        fields.push(field.field);
      });
      filter.fields = fields;
      /* TODO: когда появится запрос на скачивание
      CollectionAgentsApi
        .downloadExcel(filter)
        .then((res) => {
          this.$notification.open({
            message: new TextDecoder().decode(res.data),
            style: {
              width: '600px',
              marginLeft: `${400 - 600}px`,
              fontSize: "14px",
            },
            placement: 'bottomRight',
            duration: 3,
          });
        })
        .finally(() => (this.isLoadingExcel = false));*/
    },
    getSelectedEquipment(e) {
      this.selectedList = e;
    },
    handleClickOnRelateButton() {
      // if(this.exceptKey === 'exceptUserId') {
      //   this.relateGroups();
      //   return;
      // }
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter;
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings;
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if(from !== 'filter') {
        if (this.search !== '')
          this.applyFilterRequest$.next('containsValue');
        else
          this.debounceApplyFilter();
        return;
      }
      this.page = 0;
      this.listPage = 1;

      this.debounceApplyFilter();
    },
    debounceApplyFilter(){
      this.applyFilterRequest$.next();
    },
    updateList(value) {
      console.log(value);
      let order = '';
      let column = value.columnKey;

      if(value.order) {
        order = value.order === 'descend' ? 'desc': 'asc';
      } else {
        this.filter.sortParams = [{sortFieldName: "name", direction: "asc"}];
        this.applyFilter();
        return;
      }
      let sortParams: any =
        {sortFieldName: column, direction: order};
      this.filter.sortParams = [sortParams];
      this.applyFilter();
    },
    routeToAddCollectionAgent() {
      this.$router.push("/settings/collection-agents/add");
    },
    searchMethod(_) {
      return CollectionAgentsApi
        .applyFilter2(this.defineFilterData, this.size, this.page);
    },
    resultMethod(res) {
      this.list = res.data.data;
      this.totalCount = res.data.totalCount;
      this.isLoad = true;
    },
  },
  mounted() {
    this.applyFilter();
    if(localStorage.getItem('CollectionAgentDto')) {
      this.fields = JSON.parse(localStorage.getItem('CollectionAgentDto'));
    }
  },
})
export default class Brands extends Vue {
  list!: BrandsListData[];
  search: string;
  filter: BrandsFilterImp;
}
