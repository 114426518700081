
import { Options, Vue } from 'vue-class-component'
import locale from 'ant-design-vue/es/time-picker/locale/ru_RU'
import Accordion from '@/components/ui/Accordion.vue'
import { Field } from '@/types/fields'
import { reactive } from 'vue'
import TableSettings from '@/components/base/TableSettings.vue'
import NSIBase from '@/classes/NSIBase.vue'
import {
  getUrgencyData,
  urgencyFields,
} from '@/consts/fields/urgency.fields'
import { UrgencyData } from '@/types/urgency'
import { UrgencyFilterImp } from '@/components/classes/UrgencyFilterImp'
import { UrgencyApi } from '@/api/urgency.api'
import UrgencyList from '@/components/urgency/UrgencyList.vue'
import UrgencyFilter from '@/components/urgency/UrgencyFilter.vue'
import {currentUser} from "@/consts/currentUser";

@Options({
  components: {
    TableSettings,
    Accordion,
    UrgencyList,
    UrgencyFilter,
  },
  mixins: [NSIBase],
  data: () => {
    return {
      list: [] as UrgencyData[],
      search: '',
      isLoadingExcel: false,
      visibleSidebarFilter: false,
      visibleSidebarTableSettings: false,
      totalCount: 0,
      size: 20,
      page: 1,
      /*TODO: dynamic fields*/
      fields: reactive(
        urgencyFields.slice() as Field<UrgencyData>[]
      ),
      filter: new UrgencyFilterImp(),
      prepareData: getUrgencyData(),
      currentUser
    }
  },
  setup() {
    return {
      locale,
    }
  },
  watch: {
    search: {
      handler() {
        this.commonSearch()
      },
      deep: true,
    },
  },
  methods: {
    hasSupportEngineerRole() {
      return this.currentUser.value.userRoles.includes('support_engineer_internal')
    },
    changeStateVisibleSidebarFitler() {
      this.visibleSidebarFilter = !this.visibleSidebarFilter
    },
    toggleSuffix(el) {
      this.search = ''
    },
    changeStateVisibleSidebarTableSettings() {
      this.visibleSidebarTableSettings = !this.visibleSidebarTableSettings
    },
    handleChangeField(e, e1) {
      console.log(this.fields)
    },
    applyFilter(from?: 'filter' | unknown) {
      if (from !== 'filter') {
        if (this.search !== '') this.applyFilterRequest$.next('containsValue')
        else this.debounceApplyFilter()
        return
      }
      this.page = 0
      this.listPage = 1

      this.debounceApplyFilter()
    },
    debounceApplyFilter() {
      this.applyFilterRequest$.next()
    },
    updateList(value) {
      let order = ''
      let column = value.columnKey
      if (value.order) {
        order = value.order === 'descend' ? 'desc' : 'asc'
      } else {
        this.filter.sortParams = [{ sortFieldName: 'id', direction: 'desc' }]
        this.applyFilter()
        return
      }
      let sortParams: any = { sortFieldName: column, direction: order }
      this.filter.sortParams = [sortParams]
      this.applyFilter()
    },
    routeToAddUngencyGroups() {
      this.$router.push('/directory/urgency/add')
    },
    searchMethod(_) {
      return UrgencyApi.applyFilter2(this.defineFilterData, this.size, this.page)
    },
    resultMethod(res) {
      this.list = res.data.data
      this.totalCount = res.data.totalCount
      this.isLoad = true
    },
  },
  mounted() {
    this.applyFilter()
  },
})
export default class Urgency extends Vue {
  list!: UrgencyData[]
  search: string
  filter: UrgencyFilterImp
}
