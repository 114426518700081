
import {defineComponent} from "vue";
import CounterpartyCharacteristics from "@/components/counterparty/CounterpartyCharacteristics.vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import {CounterpartyApi} from "@/api/counterparty.api";
import {suffix} from "@/consts/nameSuff";
import ContactsList from "@/components/contacts/ContactsList.vue";
import {contactFields} from "@/consts/fields/contact.fields";
import IncidentCommentList from "@/components/incidents/IncidentCommentList.vue";
import {currentUser} from "@/consts/currentUser";

export default defineComponent ({
  name: "CounterpartyView",
  components: {
    ContactsList,
    CounterpartyCharacteristics,
    EditForm,
    DeleteDialog,
  },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    currentUser,
    selectorData: [{value: 'Сотрудники'}, {value: 'Типы объектов'}],
    currentSelect: 'Сотрудники',
    type: 'counterparty',
    contact: {
      search: '',
      fields: contactFields.slice(),
      list: [],
      size: 10,
      page: 0,
      totalCount: 0,
      sortParams: [{sortFieldName: "createTime", direction: "desc"}]
    },
    contactData: {
      contactInfo: '',
      contactType: {
        id: null,
        name: ''
      },
      counterparty: {
        id: null
      },
    },
    contactTypeList: [
      {
        id: 1,
        name: 'Телефон'
      },
      {
        id: 2,
        name: 'Email'
      },
      {
        id: 3,
        name: 'Рабочий телефон'
      },
      {
        id: 4,
        name: 'Telegram'
      },
      {
        id: 5,
        name: 'Skype'
      },
    ],
    isLoadContacts: false,
    isAddContactDialogOpen: false,
    addButton: false,
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
    const accessParams = { module: 'dictionary', object: 'contractors' };
    const tabMap = {
      'tab_common_information_contractors': '1',
      'tab_contracts_contractors': '2',
    };
    this.activeKey = this.determineActiveTab(accessParams, tabMap);
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics?.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm('Закрыть сайт? Возможно внесенные изменения не сохранятся!')
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    openDeleteDialog() {
      this.$refs.delete.openDialog("контрагента", `контрагента ${this.body.name}`);
    },
    getById() {
      CounterpartyApi.getById(this.id).then((res) => {
        this.body = res;
        this.contact.list = res.contacts

        this.isLoadContacts = true;
        console.log(this.body)
        suffix.next(' ' + res.name);
      }).finally(() => {
        this.$refs.characteristics.cleanWarning();
      });
    },
    save() {
      if(this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = this.$refs.characteristics.formatCharacteristicsCopy();
      // characteristics.email = '123@test.ru'
      // characteristics.phone = '12345678'
      // characteristics.contactUser = {id: 1}
      characteristics.supportTeamDefaultId = characteristics.supportTeamDefault.id
      if( characteristics.responsible) {
        characteristics.responsibleId = characteristics.responsible.id
      } else {
        characteristics.responsibleId = null
      }
      CounterpartyApi.save(
          characteristics
      ).then(() => {
        this.$notification.open({
          message: "Сохранено",
          style: {
            width: "330px",
            marginLeft: `${400 - 330}px`,
            fontSize: "14px",
          },
          placement: 'bottomRight',
          duration: 3,
        });
        this.getById();
      });
    },
    deleteObject() {
      CounterpartyApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    addContact() {
      this.addButton = true;
      this.contactData.counterparty.id = this.id
      CounterpartyApi.addContactToCounterParty(this.contactData)
          .then(() => {
            this.getById();
            this.closeDialog();
          })
    },
    closeDialog() {
      this.contactData.contactType.id = null;
      this.contactData.contactType.name = '';
      this.contactData.contactInfo = '';
      this.isAddContactDialogOpen = false;
    },
    openAddContactDialog() {
      this.isAddContactDialogOpen = true;
    },
    selectContactType(ev, option) {
      console.log(ev)
      console.log(option)
      this.contactData.contactType.id = option.id;
    }
  },

})
