
export const SERIES_LEGEND_WIDTH = 170;
import {defineComponent} from "vue";
import VChart from "vue-echarts";
export default defineComponent({
  name: "HybridGraphic",
  components: {
    VChart
  },
  props: {
    data: {
      default: []
    },
    title: {
      default: ''
    },
    graphicType: {
      default: 'pie'
    },
    isLoaded: {
      default: false
    }
  },
  data: () =>  ({
    SERIES_LEGEND_WIDTH,
    option: {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          return `<div style="font-family: 'Inter', sans-serif; font-size:14px;">${params.name} <span style="font-weight:600">${params.value} (${params.percent}%)</span></div>`
        }
      },
      legend: {
        show:false,
        orient: 'horizontal',
        left: 'center',
        bottom:'0px',
        itemGap: 48,
        itemHeight: 24,
        itemWidth: 32,
        textStyle: {
          fontFamily: "Inter, sans-serif",
          fontSize: 14,
          color: `#29373D`
        },
      },
      series: {
        type: '',
        top: '-10%',
        radius: ['45%', '70%'],
        data: null
      },
    },
    isGraphicUpdated: false
  }),
  methods: {
    emitClickOnLegend(part) {
      this.$emit('clickOnLegend', part)
    },
    updateSeries(value) {
      this.$refs.chart.clear();
      if(this.graphicType === 'pie') {
        this.updateForPie(value)
      }else {
        this.updateForBar(value);
      }
      if(!this.option.series.data?.length) {
        return;
      }
    },
    mounted() {
      this.option.series.type = this.graphicType
    },
    updateForPie(value) {
      this.$refs.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return `<div style="font-family: 'Inter', sans-serif; font-size:14px;">${params.name} <span style="font-weight:600">${params.value} (${params.percent}%)</span></div>`
          }
        },
        legend: {
          show:false,
          orient: 'horizontal',
          left: 'center',
          bottom:'0px',
          itemGap: 48,
          itemHeight: 24,
          itemWidth: 32,
          textStyle: {
            fontFamily: "Inter, sans-serif",
            fontSize: 14,
            color: `#29373D`
          },
        },
        xAxis: { show: false },
        yAxis: { show: false },
        series:
            {
              name: this.title,
              type: 'pie',
              top: '-10%',
              radius: ['45%', '70%'],
              emphasis: {
                label: {
                  fontFamily: "Inter, sans-serif",
                  fontSize: 20,
                  color: `#29373D`
                },
              },
              label: {
                rich: {
                  pie: {
                    fontWeight: 'bold',
                    fontSize: 14,
                  },
                  name: {
                    fontSize: 14,
                  },
                },
                formatter: function(d) {
                  return `{name|${d.name}} {pie|${d.value} (${d.percent}%)}`
                }
              },
              labelLine: {
                length2: 10,
                length: 10
              },
              data: value.map(({ title, count, color }) => ({
                name: title,
                value: count,
                itemStyle: {
                  color
                },
              }))
            }
      })
    },
    updateForBar(value) {
      let option;
      if(value[0].count instanceof Array) {
        option =  {
          height: 40*value[0].count.length + 'px',
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'none' },
            formatter: (params) => {
              console.log(params);
              // let icon0 = `<span data-tooltip="minimum" style="border-left: 2px solid #fff;
              // display: inline-block;height: 12px;margin-right: 5px;width: 20px;">
              // <span style="background-color:${
              //     params[0].color
              // };display: block;height: 4px;margin-top: 4px;width: 20px;"></span></span>`;
              let metrici = '';
              for (let i = 0; i < params.length; i++) {
                let icon0 = `<span data-tooltip="minimum" style="border-radius: 100%; display: inline-block;margin-right: 5px;"><span style="background-color:${params[i]?.color};border-radius: 100%;display: block;height: 10px;width: 10px;"></span></span>`;
                metrici += `${icon0} <span>${params[i].seriesName}</span>  <span style="font-weight:600;"> ${params[i].data.displayedValue} (${params[i].value}%)</span> <br>`;
              }
              return `<span style="font-weight:600; font-size:15px; margin-bottom:5px;">${params[0].name}</span> <br> ${metrici}`;
            }
          },
          grid: { containLabel: true },
          legend: {show:false},
          xAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value} %",
              fontFamily: "Inter, sans-serif",
              fontSize: 14,
              color: `#29373D`
            }
          },
          yAxis: {
            axisLabel: {
              fontFamily: "Inter, sans-serif",
              fontSize: 14,
              color: `#29373D`,
              interval: 0,
              rich: {
                bold: {
                  fontWeight: 'bold',
                  fontSize: 14,
                },
              },
              formatter: function (v) {
                return `${v.split('percent')[0]} {bold|${v.split('percent')[1]}}`;
              },
            },
            data: value[0].count.map(({title}) => title),
          },
          series: value.map(({title, count, color}) => {
            return  Object.freeze({
              barMinWidth: 30,
              barMaxWidth: 30,
              name: title,
              type: 'bar',
              label :{
                formatter : function (params){
                  return  params.data.displayedValue + '\n'
                },
                fontFamily: "Inter, sans-serif",
                fontSize: 14,
                verticalAlign: 'middle',
                lineHeight: 0,
                fontWeight: 600,
                color: `#29373D`,
                show: true,
              },
              color,
              stack: 'total',
              data: count.map(({value, title, name, displayedValue}) => ({
                name,
                title,
                value,
                displayedValue,
                itemStyle: {
                  color
                },
              })),
            })
          })
        }
      } else {
        option =  {
          tooltip: {
            trigger: 'item',
            formatter: (params) => {
              return `<div style="font-family: 'Inter', sans-serif; font-size:14px;">${params.name} <span style="font-weight:600">${params.value}</span></div>`
            }
          },
          legend: {
            show:false,
            orient: 'horizontal',
            left: 'center',
            bottom:'0px',
            itemGap: 48,
            itemHeight: 24,
            itemWidth: 32,
            textStyle: {
              fontFamily: "Inter, sans-serif",
              fontSize: 14,
              color: `#29373D`
            },
          },
          xAxis: {show:false, data: value.map(({title}) => title)},
          yAxis: {
            axisLabel: {
              fontFamily: "Inter, sans-serif",
              fontSize: 14,
              color: `#29373D`
            }},
          series: Object.freeze({
            name: this.title,
            type: 'bar',
            emphasis: {
              label: {
                fontFamily: "Inter, sans-serif",
                fontSize: 20,
                color: `#29373D`
              },
            },
            label :{
              rich: {
                pie: {
                  fontWeight: 'bold',
                  fontSize: 14,
                },
                name: {
                  fontSize: 14,
                },
              },
              formatter: function(d) {
                return `{name|${d.name}} {pie|${d.value} (${d.percent}%)}`
              }
            },
            data: value.map(({title, count, color}) => Object.freeze({
              name: title,
              value: count,
              itemStyle: {
                color
              },
            })),
          })
        }
      }
      this.$refs.chart.setOption(option)
    }
  },
  watch: {
    isLoaded: {
      handler(val) {
        if(!val) {
          this.option.series.data = [];
        }
      }
    },
    data: {
      handler(val) {
        this.updateSeries(val)
      },
      deep: true
    },
    graphicType:  {
      handler(val) {
        this.updateSeries(this.data)
      },
      deep: true
    }
  }
})
