
import FilterBase from "@/classes/FilterBase.vue";
import {Field} from "@/types/fields";
import {defineComponent, PropType} from "vue";
import {ModelsFilterImp} from "@/components/classes/ModelsFilterImp";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {Util} from "@/components/utils/Util";
import Accordion from "@/components/ui/Accordion.vue";

export default defineComponent({
  name: "ModelsFilter",
  components: {
    Accordion
  },
  mixins: [FilterBase],
  props: {
    fields: Object as PropType<Field<unknown>[]>,
  },
  watch: {
    fields: {
      handler() {
        this.setFilterFields();
        this.applyFilter('filter');
      },
      deep: true
    }
  },
  methods: {
    changeFilter() {
      this.setQueryParams();
    },
    applyFilter() {
      this.filter.brands = (this.valuesForCheckboxes.brandsArr.length === 1
          && this.valuesForCheckboxes.brandsArr[0] === '')
          ? [] : this.valuesForCheckboxes.brandsArr;
      this.filterChange(new ModelsFilterImp());
      this.$emit("filter", this.filter);
    },
    getProducer() {
      MonitoringObjectsApi
          .getProducerList(null, 100)
          .then((res) => {
            this.valuesForCheckboxes.brands = Util.transformToCheckboxes(res);
          });
    },
    setQueryParams() {
      if (!this.$route.query.isRelated) {
        let queryParams: any = {};
        this.setQueryFromFilterParam(queryParams, ['name', 'id']);
        this.setQueryParamsArrDefault(queryParams, ['brands']);
        this.$router.push({query: queryParams});
      }
    },
    getFieldsVisible(name) {
      let result = this.fields.find(obj => {
        return obj.field === name && obj.value === true;
      });
      return result;
    },
    getQueryParams(){
      const query = this.$route.query;
      this.setFilterFromQueryParam(query, ['name', 'id']);
      this.setCheckboxesArrFromQuery(query, ["brands"]);
      if(query.incidentStatuses) {
        this.valuesForCheckboxes.incidentStatusesArr[0] = query.incidentStatuses;
      }
    },
    cleanFilter() {
      this.filter = new ModelsFilterImp();
      this.setFilterFields();
      this.filterChange(new ModelsFilterImp());
      this.$emit("filter", this.filter);
    },
    setFilterFields() {
      this.fields.filter((v) => v.value === false)
          .forEach(f => {
            if(f.field === 'id') {
              this.filter.id = '';
            }
            if(f.field === 'name') {
              this.filter.name = '';
            }
            if(f.field === 'brand') {
              this.valuesForCheckboxes.brandsArr = [''];
            }
          });
    },
  },
  data: () => ({
    filter: new ModelsFilterImp(),
    valuesForCheckboxes: {
      brands: [],
      brandsArr: [],
    },
    filterData: {}
  }),
  mounted() {
    Promise.all([this.getProducer()])
        .then(() => {
          this.getQueryParams();
          this.setFilterFields();
          this.applyFilter();
        });
  },
})
