
import {defineComponent} from "vue";
import DashboardSupersetCard from "@/views/dashboards/DashboardSupersetCard.vue";
import {DashboardsApi} from "@/api/dashboards.api";

export default defineComponent({
  components: {DashboardSupersetCard },
  mounted() {
    this.getSupersetData();
  },
  data: () => ({
    supersetSettings: {
      url: '',
      token: '',
      content: []
    },
  }),
  methods: {
    getSupersetData() {
      DashboardsApi.getSupersetDashboard().then((res) => {
        console.log(res, 'superset')
        this.supersetSettings = res;
      })
    }
  },

})
