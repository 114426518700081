
import {defineComponent} from "vue";
import ListBase from "@/classes/ListBase.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {currentUser} from "@/consts/currentUser";
import { getStatusClass, getStatusStyle } from "@/helpers/define-status-color";

export default defineComponent({
  components: {DeleteDialog},
  mixins: [ListBase],
  props: {
    hierarchy: {
      default: false,
    }
  },
  watch: {
    hierarchy: {
      handler() {
        this.openPages = [];
        if (this.hierarchy) {
          this.setOpenPages(this.list);
        }
       }
    },
    list: {
      handler() {
        this.list.map(res => {
          res.checked = this.selectedArray.includes(res.id)
          return res;
        })
        console.log(this.list)
        if(this.hierarchy) {
          this.nestedCount = this.count(this.$props.list[0].childMonitoringObjects)
        }
      },
      deep: true
    }
  },
  data: () => ({
    selectedId: '',
    openPages: [],
    nestedCount: 0,
    currentUser,
    yes: true,
  }),
  methods: {
    getStatusClass,
    getStatusStyle,
    getFieldStyle(field, fieldValue) {
      console.log('FIELD', field, fieldValue);
      return field?.color ? `color: ${field.color}` : '';
    },
    getFieldClass(field, fieldValue) {
      console.log('FIELD CLASS', field, fieldValue);
      if (field?.getClass) {
        return { [field.getClass(fieldValue)]: true };
      }
      return '';
    },
    deleteRelatedEquipment() {
      console.log(this.typeRelate)
      if (this.typeRelate === 'service') {
        MonitoringObjectsApi.deleteMonitoringObjectService(this.selectedId, this.relatedId)
            .then(() => {
              this.onChange(this.currentPage, this.currentSize);
              this.$emit("delete")
            })
        return;
      }
      if (this.typeRelate === 'health-model-rule') {
        MonitoringObjectsApi.deleteMonitoringObjectHealthModelRule(this.selectedId, this.relatedId)
            .then(() => {
              this.onChange(this.currentPage, this.currentSize);
            })
        return;
      }
      if (this.typeRelate === 'area') {
        MonitoringObjectsApi.deleteMonitoringObjectArea(this.selectedId, this.relatedId)
            .then(() => {
              this.onChange(this.currentPage, this.currentSize);
            })
        return;
      }
      if (this.typeRelate === 'monitoring-objects') {
        MonitoringObjectsApi.deleteParentMonitoringObject(this.selectedId)
            .then(() => {
              this.onChange(this.currentPage, this.currentSize);
            })
        return;
      }
      if (this.typeRelate === 'contracts') {
        MonitoringObjectsApi.deleteContractFromMonitoringObject(this.relatedId,this.selectedId)
            .then(() => {
              this.onChange(this.currentPage, this.currentSize);
            })
        return;
      }
    },
    openDeleteDialog(equipmentId, name) {
      this.selectedId = equipmentId;
      this.$refs.delete.openDialog("связи", `связь с объектом ${name}`);
    },
    expand(expanded, record) {
      const id = record.id;
      console.log(record);
      if (expanded) {
        if (!this.openPages.includes(id)) {
          const set = new Set(this.openPages);
          set.add(id);
          this.openPages = [...set];
        }
      } else {
        this.openPages.splice(this.openPages.indexOf(id), 1);
      }
    },
    setOpenPages(list) {
      list.forEach(l => {
        this.openPages.push(l.id);
        if (l.childMonitoringObjects) {
          this.setOpenPages(l.childMonitoringObjects);
        }
      });
    },
    count(children) {
      return children.reduce((depth, child) => {
        return Math.max(depth, 1 + this.count(child.childMonitoringObjects))
      }, 1);
    },
    hasAccess(roleName) {
      return this.currentUser.value.userRoles.includes(roleName)
    }
  },
});
