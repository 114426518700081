
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {getModelsData} from "@/consts/fields/models.fields";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";

export default defineComponent({
  name: "ModelCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getModelsData(),
    validationFields:[
      {field: "name", validators: ["required"]},
    ],
    brandList: [],
  }),
  mounted() {
    this.getBrandList();
    this.getCRUDSettings(110)
  },
  methods: {
    clean(){
      this.data = getModelsData();
    },
    getBrandList() {
      MonitoringObjectsApi.getProducerList().then((res) => {
        this.brandList = res;
      });
    },
    onSelectBrand(value, option) {
      const key = "id";
      this.data[option.field][key] = option[key];
    },
  }
})
