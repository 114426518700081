import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"125% !important"} }
const _hoisted_2 = {
  class: "text-bold text-gray-9 cursor-pointer flex align-items-center",
  style: {"margin-left":"86.55%","padding":"0px !important"}
}
const _hoisted_3 = {
  ref: "superset",
  id: "test",
  class: "superset"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsDialog = _resolveComponent("SettingsDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("template", _hoisted_1, [
      _createVNode(_component_SettingsDialog, {
        ref: "settingsDialog",
        style: {"width":"125% !important"}
      }, null, 512)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDashboardSettings()))
      }, "Настройка дашборда")
    ]),
    _createElementVNode("div", _hoisted_3, null, 512)
  ], 64))
}