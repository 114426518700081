
import {defineComponent} from "vue";
import CharacteristicBase from "../../classes/CharacteristicBase.vue";
import {MonitoringObjectsApi} from "@/api/monitoring-objects.api";
import {AreaApi} from "@/api/area.api";
// import {ServiceApi} from "@/api/service.api";
import {defineTextColorByStatus} from "@/helpers/define-status-color";
import {getMonitoringObjectsData} from "@/consts/fields/monitoring-objects.fields";
import {ClientsApi} from "@/api/clients.api";
import {HealthModelRuleApi} from "@/api/health-model-rule.api";
import {ContractsApi} from "@/api/contracts.api";
import {currentUser} from "@/consts/currentUser";
import {formatDate} from "@/filters/date";

export default defineComponent({
  name: "MonitoringObjectCharacteristics",
  mixins: [CharacteristicBase],
  data: () => ({
    data: getMonitoringObjectsData(),
    currentUser,
    statusList: [],
    brandList: [],
    typeList: [],
    // servicesList: [],
    modelList: [],
    areaList: [],
    healthModelList: [],
    clientList: [],
    contractList: [],
    areaListTotalCount: 0,
    areaListCurrentSize: 25,
    clientListTotalCount: 0,
    clientListCurrentSize: 25,
    contractListTotalCount: 0,
    contractListCurrentSize: 25,
    contractStepLoadMore: 25,
    validationFields: [
      {field: "name", validators: ["required"]},
      {field: "status.name", validators: ["required"]},
      {field: "ipaddress", validators: ["ipaddress"]}
      ]
  }),
  mounted() {
    this.getStatusList();
    this.getBrandList();
    this.getTypeList();
    this.getModelList();
    this.getAreaList();
    this.getClients();
    this.getContracts();
    // this.initServices();
    this.getCRUDSettings(53);
  },
  methods: {
    formatDate,
   defineTextColorByStatus,
    getClients() {
      ClientsApi.applyFilter({name: this.data.client.name}, this.clientListCurrentSize, 0).then((res) => {
        this.clientList = res.data;
        this.clientListTotalCount = res.totalCount;
      });
    },
    getContracts() {
     ContractsApi.applyFilter({},this.contractStepLoadMore,0).then((res) => {
       this.contractList = res.data;
       this.contractListTotalCount = res.totalCount;
     })
    },
    getContractsMore() {
      this.contractStepLoadMore += 5;
      ContractsApi.applyFilter({},this.contractStepLoadMore,0).then((res) => {
        this.contractList = res.data;
        this.contractListTotalCount = res.totalCount;
      })
    },
    getContractsSearch(name: any) {
      name = name === undefined ? '' : name
      ContractsApi.applyFilter({name: `${name}`},this.contractStepLoadMore,0).then((res) => {
        this.contractList = res.data;
        this.contractListTotalCount = res.totalCount;
      })
    },
    onSelectContract(value,option) {
      console.log(value)
      console.log(option)
      this.data.contract = {
        name: option.value,
        id: option.id
      }
    },
    onSelectClient(value, option) {
      this.data.client = {
        name: option.value,
        id: option.id,
      };
    },
    onSelectArea(value, option) {
      this.data.area = {
        name: option.value,
        id: option.id
      }
    },
    getStatusList() {
      MonitoringObjectsApi.getStatuses().then((res) => {
        this.statusList = res;
      });
    },
    getBrandList() {
      MonitoringObjectsApi.getProducerList().then((res) => {
        this.brandList = res;
      });
    },
    getTypeList() {
      MonitoringObjectsApi.getTypesList().then((res) => {
        this.typeList = res.data;
      });
    },
    getModelList() {
      MonitoringObjectsApi.getModelList().then((res) => {
        this.modelList = res;
      });
    },
    onSelectBrand(value, option) {
      const key = "id";
      this.data[option.field][key] = option[key];
      MonitoringObjectsApi.getModelList('',100,[option[key]], 0).then((res) => {
        this.modelList = res;
      });
      this.data.model.name = null;
    },
    getAreaList() {
      AreaApi.applyFilter({ name: this.data.area.name }, this.areaListCurrentSize, 0).then((res) => {
        this.areaList = res.data;
        this.areaListTotalCount = res.totalCount;
      });
    },
    getHealthModelTemplate() {
     HealthModelRuleApi.applyFilter({name: this.data.healthModelTemplate.name},100).then((res) => {
       this.healthModelList = res.data;
     })
    },
    selectArea(value, option) {
      this.onSelect(value, option);
      // this.cleanSelect({field: "service"});
    },
    cleanAutocomplete(option, event?) {
      if(!event) {
        this.cleanSelect(option);
      }
    },
    canEditResponseZone() {
      return  this.currentUser.value.userRoles.includes('client-operator')
    },
    cleanSelect(option) {
      if(option.field === "area") {
        this.data["area"] = {id: null, name: "", address: "", code: ""};
        // this.data["service"] = {id: null, orderN: "", channelType: ""};
        this.getAreaList();
        // this.initServices();
      }
      // if(option.field === "service") {
      //   this.data["service"] = { id: null, orderN: "", channelType: "" };
      //   this.initServices();
      // }
    },
    // initServices() {
    //   if (this.data.area.id) {
    //     ServiceApi.applyFilter(
    //         { orderN: this.data.service.value, areaId: this.data.area.id },
    //         10,
    //         0
    //     ).then((res) => {
    //       this.servicesList = res.data;
    //     });
    //   } else {
    //     this.servicesList = [];
    //   }
    // },

    onSelectStatus(value) {
      const selectedStatus = this.statusList.find(status => status.name === value);
      if (selectedStatus) {
        this.data.status = { ...selectedStatus };
      }
    },
    onSelect(value, option) {
      // if(option.field === "service") {
      //   this.data[option.field] = option;
      //   this.$emit("select", { option });
      //   return;
      // }
      const key = option.field === "service" ? "orderN" : "id";
      this.data[option.field][key] = option[key];
    },
    onChange(value, option) {
      if (!value) {
        this.data.contract.id = undefined;
      } else {
        const key = option.field === "service" ? "orderN" : "id";
        this.data[option.field][key] = option[key];
      }
    },
    loadMoreArea() {
      this.areaListCurrentSize += 25;
      this.getAreaList();
    },
    loadMoreClient() {
      this.clientListCurrentSize += 25;
      this.getClients();
    }
  },
  watch: {
    data: {
      handler() {
        if (this.data.id) {
          this.getHealthModelTemplate();
        }
      },
      deep: true
    }
  }
});
