
import { defineComponent } from "vue";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import SettingsDialog from "@/views/dashboards/SettingsDialog.vue";

export default defineComponent({
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  components: {
    SettingsDialog
  },
  mounted() {
    this.init();
  },
  data: () => ({}),
  methods: {
    openDashboardSettings() {
      this.$refs.settingsDialog.openDialog("дашборда", "текст");
    },
    init() {
      if (this.settings.content.length > 0) {
        embedDashboard({
          id: this.settings.content[0].id,
          supersetDomain: this.settings.url,
          mountPoint: this.$refs.superset,
          fetchGuestToken: () => this.fetchGuestTokenFromBackend(),
          dashboardUiConfig: {
            hideTitle: true,
            hideTab: true,
            hideChartControls: true,
            filters: {
              expanded: false,
              visible: false
            }
          }
        });
      }
    },
    async fetchGuestTokenFromBackend() {
      return await this.settings.guestToken;
    }
  },
  watch: {
    settings() {
      this.init();
    }
  }

});
