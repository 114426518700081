
import { defineComponent, reactive } from "vue";
import EditForm from "@/components/base/EditForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ArchiveDialog from "@/components/dialogs/ArchiveDialog.vue";
import ViewBase from "@/classes/ViewBase.vue";
import CollectionAgentCharacteristics from "@/components/collection-agents/CollectionAgentCharacteristics.vue";
import { CollectionAgentsApi } from "@/api/collection-agents.api";
import SchedulerRunEventList from "@/components/scheduler-run-events/SchedulerRunEventList.vue";
import { schedulerRunEventData, schedulerRunEventFields } from "@/consts/fields/scheduler-run-event.fields";
import { Field } from "@/types/fields";
import {suffix} from "@/consts/nameSuff";

export default defineComponent({
  components: { CollectionAgentCharacteristics, EditForm,  DeleteDialog, SchedulerRunEventList, ArchiveDialog },
  mixins: [ViewBase],
  data: () => ({
    body: {},
    schedulerRunEvent: {
      list: [],
      size: 20,
      page: 0,
      listPage: 1,
      fields: reactive(
        schedulerRunEventFields.slice() as Field<schedulerRunEventData>[]
      ),
    },
  }),
  mounted() {
    this.id = this.$route.params.id;
    this.getById();
    this.getSchedulerRunEvent();
  },
  beforeRouteLeave(to, from, next) {
    suffix.next(null);
    if (!this.$refs.characteristics.isWarning) {
      window.onbeforeunload = undefined;
      next();
      return;
    }
    const answer = window.confirm(
      "Закрыть сайт? Возможно внесенные изменения не сохранятся!"
    );
    if (answer) {
      window.onbeforeunload = undefined;
      next();
    } else {
      next(false);
    }
  },
  methods: {
    updatePage({ page, size }) {
      this.schedulerRunEvent.size = size;
      this.schedulerRunEvent.page = page - 1;
      this.schedulerRunEvent.listPage = page;
      this.getSchedulerRunEvent();
    },
    openDeleteDialog() {
      this.$refs.delete.openDialog(
        "агента сбора данных ",
        `агент сбора данных ${this.body.name}  и очистить журнал обработок`
      );
    },
    openArchiveDialog(){
      if (this.body.deleted) {
        this.$refs.archive.openDialog("Восстановление агента сбора данных ",
            `восстановить из архива  агент сбора данных ${this.body.name}  и журнал обработок` , "Восстановить");
      }
      else {
        this.$refs.archive.openDialog("Архивирование агента сбора данных ",
            `архивировать  агент сбора данных ${this.body.name}  и журнал обработок`, "В архив");
      }
    },
    getById() {
      CollectionAgentsApi.getById(this.id)
        .then((res) => {
          this.body = res;
          suffix.next(' ' + res.name);
        })
        .finally(() => {
          this.$refs.characteristics.cleanWarning();
        });
    },
    getSchedulerRunEvent() {
      this.isLoad = false;
      CollectionAgentsApi.getSchedulerRunEvent(
        {
          monitoringSystemId: this.id,
          sortParams: this.schedulerRunEvent.sortParams,
        },
        this.schedulerRunEvent.size,
        this.schedulerRunEvent.page
      ).then((res) => {
        this.schedulerRunEvent.totalCount = res.totalCount;
        this.schedulerRunEvent.list = res.data;
        this.isLoad = true;
      });
    },
    updateList(value) {
      this.isLoad = false;
      let order = "";
      let column = "";
      if (value.columnKey === "active") {
        column = "activity";
      } else {
        column = value.columnKey;
      }
      if (value.order) {
        order = value.order === "descend" ? "desc" : "asc";
      } else {
        this.schedulerRunEvent.sortParams = [
          { sortFieldName: "id", direction: "desc" },
        ];
        this.getSchedulerRunEvent();
        return;
      }
      let sortParams: any = { sortFieldName: column, direction: order };
      this.schedulerRunEvent.sortParams = [sortParams];
      this.getSchedulerRunEvent();
    },
    save() {
      if (this.$refs.characteristics.validate()) {
        return;
      }
      const characteristics = Object.assign(
        {},
        this.$refs.characteristics.data
      );
      characteristics.cron = this.$refs.characteristics.defineCronValue();
      CollectionAgentsApi.save(characteristics).then(() => {
        this.getById();
      });
    },
    deleteObject() {
      CollectionAgentsApi.delete(this.id).then(() => {
        this.$router.go(-1);
      });
    },
    archiveObject() {
      if (this.body.deleted) {
        CollectionAgentsApi.unArchive(this.id).then(() => {
          this.getById();
        });
      }
      else {
        CollectionAgentsApi.archive(this.id).then(() => {
          this.getById();
        });
      }
    }
  },
});
